jQuery(document).ready(function ($) {

    $('.timetable .place-title').click(function (e) {
        $(this).toggleClass('open');
        $(this).next('.events').toggleClass('open');
    });

    $('.timetable .toggle-all').click(function (e) {
        $(this).find('.toggle-title').toggleClass('open');
        var open = false;
        if($(this).find('.toggle-title').hasClass('open')){
            open = true;
        }
        $(this).parent('.timetable').find('.place-title').each(function() {
            if (open !== true) {
                if ($(this).hasClass('open')) {
                    $(this).toggleClass('open');
                    $(this).next('.events').toggleClass('open');
                }
            } else {
                if (!($(this).hasClass('open'))) {
                    $(this).toggleClass('open');
                    $(this).next('.events').toggleClass('open');
                }
            }
        });
    });

    $('.day-selector-button').click(function(e){
        e.preventDefault();
        if($(this).hasClass('first-day-button')){
            timetable = $('.timetable.first');
            selected_day = 'first';
        }else if($(this).hasClass('second-day-button')){
            timetable = $('.timetable.second');
            selected_day = 'second';
        }else{
            timetable = $('.timetable.third');
            selected_day = 'third';
        }
        if (timetable.hasClass('current')){}
        else{
            $(this).addClass('current');
            timetable.addClass('current');
            $('.day-selector-button').not(this).each(function () {
                $(this).removeClass('current');
            });
            $('.timetable').not(timetable).each(function () {
                $(this).removeClass('current');
            });
            var stateObj = { day: selected_day };
            var page = $(this).data('slug');
            history.pushState(stateObj, "", "/"+page+"/?timetable_day="+selected_day);
        }
    });

    $('.filter-category').click(function(e){
        e.preventDefault();
        filterCount = $('.filter-category.selected').length;
        if($(this).hasClass('selected')){
            $(this).removeClass('selected');
            category = $(this).data('category');
            $( ".timetable .event" ).each(function() {
                if($(this).hasClass(category)){
                    if(filterCount > 1){
                        $(this).addClass('filter');
                    }
                }
                else{
                    if(filterCount === 1){
                        $(this).removeClass('filter');
                    }
                }
            });
        }else{
            $(this).addClass('selected');
            category = $(this).data('category');
            $( ".timetable .event" ).each(function() {
                if($(this).hasClass(category)){
                    $(this).removeClass('filter');
                }
                else{
                    if(filterCount === 0){
                        $(this).addClass('filter');
                    }
                }
            });
        }
    });

    $('.info').hover(
        function(){ $(this).siblings('.infobox').addClass('visible') },
        function(){ $(this).siblings('.infobox').removeClass('visible') }
    )

});
